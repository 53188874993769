import MenuBar from './components/MenuBar'
import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import  Info  from './components/Info';
import  Home  from './components/Home';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<MenuBar />}> </Route>
            <Route exact path = "/" element={<Home />} />
            <Route path="info" element={<Info />} />
        </Routes>
      </Router>   
      <div className="component">
      <div className='namn-font'>
        Amelia & Jonas
        <br></br>
      <div className='datum-font'>
        21-06-2025
      </div>
      <div className='ort-font'>
        Vännfors, Västerbotten
      </div>
      </div>
  </div>
    </div>
    
  );
}

export default App;

import MenuBar from './MenuBar'
const Info = () => {
    return <div className="component">
        <MenuBar></MenuBar>
        <div className="info-box">

   <b>Plats</b>
   <br></br>
   <br></br> Kullar & Klang 
   <br></br>
   Vännforsbäck 23
   <br></br>
   911 92 Vännäs
   <br></br><br></br>
   <hr></hr> 
   <br></br><br></br>  

   <b>Vigsel</b>
   <br></br>
   <br></br>
   Vigsel startar kl 15:00
   <br></br><br></br>
   Drop in från kl 14:00
   <br></br><br></br>
   <hr></hr> 
   <br></br><br></br>

   <b>Fest</b>
   <br></br>
   <br></br> Festen & middagen hålls i ladan på gården.
   <br></br><br></br>
   Barn är jättevälkomna under vigseln, men fest & middag får dom skippa.
   <br></br><br></br>
   <hr></hr> 
   <br></br><br></br>

   <b>Boende</b>
   <br></br>
   <br></br> Boende behöver ordnas på egen hand, vi rekommenderar Stora Hotellet eller Mimer.
   <br></br>
   <br></br><br></br>
   <hr></hr> 
   <br></br><br></br>

   <b>Transport</b>
   <br></br>
   <br></br> <i> Uppdateras inom kort. </i>
   <br></br><br></br>
   <hr></hr> 
   <br></br><br></br>

   <b>Klädsel</b>
   <br></br>
   <br></br> Kostym
   <br></br><br></br>
   <hr></hr> 
   <br></br><br></br>

   <b>Tal</b>
   <br></br>
   <br></br> Om ni är peppade på att hålla tal, kan ni anmäla det till våra
   <br></br> Toastmasters via:
   <br></br><br></br>
   amelia.jonas.toast@gmail.com
   <br></br><br></br>
   <hr></hr> 
   <br></br><br></br>

   <b>OSA</b>
   <br></br>
   <br></br> OSA senast den 30:e april, via:
   <br></br> amelia.jonas.brollop@gmail.com
   <br></br><br></br>
   + eventuella allergier.
   <br></br><br></br>
   <hr></hr> 
   <br></br><br></br>

   <b>Bröllopsgåva</b>
   <br></br>
   <br></br> Att ni kommer på vårat bröllop är allt vi önskar oss, men om ni vill ge en bröllopspresent, får ni gärna bidra med en slant till vår bröllopsresa till Wales.
   <br></br><br></br>
   <hr></hr> 
   <br></br><br></br>

   Vi ses till midsommar! ♡
   <br></br><br></br>
</div>
        </div>;
  };
  
  export default Info;
import MenuBar from './MenuBar'
const Home = () => {
    return  <div className="component">
      <MenuBar></MenuBar>
      <div className='namn-font'>
        Amelia & Jonas
      </div>

  </div>


  };
  
  export default Home;
import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";

function MenuBar() {
  const pages = [
    { text: "Hem", path: "/" },
    { text: "Info", path: "/info" },
  ];

  return (

        <Toolbar disableGutters>
        
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "flex" },

              justifyContent: "center",
            }}
          >
            {pages.map((page, index) => (
                <Link to={page.path} className="link-style" key={index}>
                  {page.text}
                </Link>
              ))}
          
          </Box>
        </Toolbar>
  );
}
export default MenuBar;